@import 'variables';
@import 'placeholders';

.modal-backdrop {
  &-enter {
    opacity: 0;
  }

  &-enter-to {
    opacity: 1;
  }

  &-leave-to {
    opacity: 0;
  }
}

.modal {
  &-enter {
    transform: translateY(100%);
  }

  &-enter-to {
    transform: translateY(0);
  }

  &-leave-to {
    transform: translateY(100%);
  }
}
