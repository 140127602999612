div.message.members-banner.logged-out {
  a {
    color: $secondary-color;

    &:hover,
    &:focus {
      color: $primary-color;
    }
  }
}

.body-inner .site-content-inner {
  padding-top: 16px;
}

.filters {
  padding-bottom: 24px;
  margin-bottom: 32px;
  border-bottom: $divider;
}

.event-listing {
  article.event {
    a.title-link {
      margin-top: 0;
    }

    h2 {
      margin-top: 4px;
      margin-bottom: 10px;
    }

    .subtitle {
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 10px;
    }
  }
}

section.cart {
  border: 1px solid $light-gray;

  h2 {
    background-color: $primary-color;
    color: white;
  }

  button.primary.purchase {
    font-size: 20px;
    font-weight: 800;
    padding: 22px 14px;
    text-transform: none;

    &:hover {
      background-color: $medium-gray;
    }
  }

  .content {
    .empty-cart-message {
      padding: 22px;
    }

    .ticket-date {
      margin-bottom: 0;
    }

    .ticket-wrapper {
      .actions {
        button.remove {
          color: $dark-gray;
        }
      }
    }

    .add-promo-button {
      color: $primary-color;
      text-transform: uppercase;
      font-size: 13px;
      font-weight: 700;
      transition: $transition;

      &:enabled:hover {
        color: $blue;
      }
    }

    .ticket-type {
      margin-bottom: 0;
    }
  }
}

.stepper {
  > .count {
    background-color: $light-gray;
    font-weight: 700;
  }

  &.active {
    > .count {
      background-color: $medium-gray;
      border-color: $medium-gray;
      color: white;
    }
  }
}

.complete {
  .main {
    padding: 0 32px;

    h1 {
      font-size: 24px;
      letter-spacing: 0;

      span {
        color: $primary-color;
        margin-bottom: 8px;
        font-size: 48px;
      }
    }

    code.order-number {
      font-weight: 800;
      color: $primary-color;
    }
  }
}

form.buy-membership {
  .member-names {
    .radio-item {
      .label {
        margin-bottom: 4px;
        font: $body-font;
      }
    }

    .name-fields {
      margin-top: 24px;
    }
  }
}

.radio-items {
  .radio-item {
    margin-bottom: 40px;
  }
}

.checkout-route {
  .mobile-checkout-heading {
    letter-spacing: 0;
    border-bottom: 2px solid #231f20;
  }

  .purchaser-details,
  .payment,
  .terms-conditions,
  .upsell {
    &:not(:last-child) {
      border-bottom: $divider;
      padding-bottom: 40px;
      margin-bottom: 40px;
    }
  }

  .apply-gift-cards {
    .add-card-form {
      .form-container {
        button.apply-card {
          margin: 14px 16px;
        }
      }
    }
  }

  .purchaser-details {
    div.message.info {
      margin-bottom: 0;
    }
  }
}

.donation,
.gift-cards {
  label.radio-item {
    .radio-description {
      .label {
        @extend %h3;
      }
    }
  }
}

.modal {
  .modal-header {
    border-bottom: 0;
  }

  .cart .content .add-promo-form {
    padding-left: 0;
    padding-right: 0;
  }

  &.membership-modal {
    .modal-body {
      @include large {
        display: flex;

        @include membershipModalImage(auto) {
          background-image: url(images/membership-modal.jpg);
          flex-basis: 336px;
        }

        .membership-modal-inner {
          width: auto;
          padding-left: 16px;
        }
      }
    }
  }
}

input[type='checkbox'] {
  border-radius: 0;
  border-color: $light-gray;

  &:hover {
    border-color: $medium-gray;
  }

  &:checked {
    background-color: transparent;
    border-color: $light-gray;

    &:before {
      border-color: $dark-gray;
    }
  }
}

.content-detail-page {
  .select-tickets {
    .ticket-group-description-wrapper {
      > h2 {
        margin-bottom: 0;
      }
    }

    .ticket-type-description {
      .subtitle {
        margin-top: 0;
      }
    }
  }

  .select-session,
  .select-date {
    h3 {
      margin-bottom: 8px;
    }
  }

  .description-wrapper {
    .subtitle {
      font-weight: bold;
    }
  }
}

.select-tickets {
  .ticket-type:last-child {
    margin-bottom: 44px;
  }
}

.filters a.active {
  background-color: transparent;
  border-color: $dark-gray;
  color: $dark-gray;
}

.event-listing {
  article.event {
    h2 {
      margin-bottom: 10px;
    }
  }
}

.event-listing {
  article.event {
    figure {
      @include large {
        padding-right: 0;
      }

      img {
        width: 100%;
      }
    }
  }
}

details.member-menu {
  .greeting-title {
    color: $black;
  }

  ul.member-menu-items {
    li {
      button {
        font-weight: 400;
      }

      &:not(:last-of-type) {
        border-bottom: 1px solid $light-gray;
      }
    }
  }
}

.selected-date-time,
.selectable-button {
  padding: 10px 12px;
}

.select-available-session,
.date-selector {
  .selectable-button {
    border: 1px solid $light-gray;
    color: $primary-color;
    min-height: 50px;
    transition: $transition;

    &.available {
      @include hover {
        border-color: $primary-color;
      }
    }
  }
}

.my-account-wrapper {
  .session-detail-route .ticket-row {
    border-top: none;
    border-right: none;
    border-left: none;
    margin-bottom: 0;

    &:last-child {
      border-bottom: none;
    }
  }
}

.session-detail-route .num-tickets {
  background-color: $very-light-gray;
}
