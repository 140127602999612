@import "@/styles/globals.scss";
@import '../../styles/defaults/css-properties';

// Variables
$black: #231f20;
$dark-gray: $black;
$medium-gray: #5a5758;
$light-gray: #bcbbbc;
$very-light-gray: #ededed;
$blue: #1056ce;

$body-color: $dark-gray;
$heading-color: $dark-gray;

$transition: all 200ms ease-out;

$divider: 2px solid $primary-color;

$steppers-3-box-size: 40px;
$steppers-3-button-background-color: transparent;
$steppers-3-button-background-hover-color: transparent;

$radio-button-border-color: $light-gray;
$radio-button-checked-border-color: $dark-gray;

$datepicker-icon-color-active: white;

$members-menu-icon-color: $black;

$cart-price-background-color: $very-light-gray;

// Defaults
@import '../../styles/defaults/index';
@import '../../styles/helpers/index';

// Placeholders
%h1,
%h2,
%h3,
%h4,
%h5,
%h6 {
  font-weight: 800;
}

%h1 {
  letter-spacing: -2px;
  font-size: 48px;

  @include small {
    font-size: 40px;
  }
}

%h2 {
  font-size: 24px;
  letter-spacing: 0;
}

%button {
  transition: $transition;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 700;
}

%primary-button {
  padding: 14px 12px 13px;
  background-color: $primary-color;
}

%primary-button-hover {
  background-color: $medium-gray;
}

%secondary-button {
  border: 1px solid $light-gray;
  color: $primary-color;
  background-color: transparent;
  padding: 13px 12px 12px;
}

%secondary-button-hover {
  border-color: $primary-color;
  background-color: transparent;
  color: $primary-color;
}

%secondary-button-active {
  background-color: $medium-gray;
  border-color: $medium-gray;
  color: white;
}

%back-button {
  color: $primary-color;
  text-transform: uppercase;
}

%input {
  border-color: $light-gray;
  padding: 10px 12px;
}

%label {
  margin-bottom: 4px;
}

%card {
  border-color: $light-gray;
  border-width: 1px;
}

// Base elements
@import 'page';
@import 'elements';
@import 'links-and-buttons';
@import 'form-inputs';
@import 'modals';
@import 'messages';
@import 'badges';

// Components
@import '_header';
@import '_footer';
@import 'filters';
@import 'actions';
@import 'cart';
@import 'selection';
@import 'steppers-3';
@import 'calendar';
@import 'identity-form';
@import 'members-menu';
@import 'legend';

// Routes
@import 'checkout';
@import 'completed';
@import 'event-list';
@import 'buy-membership';
@import 'gift-of-membership';
@import 'forgot-membership';
@import 'event';
@import 'resend-tickets';
@import 'manage-order';
@import 'home';
@import 'my-account';

// Theme
@import 'sass/styles';
@import 'sass/header';
@import 'sass/footer';

// Mixins
@include twoColumnEventsList(24px);
