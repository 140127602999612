.identity-form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  > * {
    flex-basis: 100%;
  }

  // Tenant-specific CSS might want to layout some identity form fields on the same line. E.g.
  //
  //  @include mediumAndLarge {
  //    > .city,
  //    > .state {
  //      flex-basis: calc(50% - 8px);
  //    }
  //
  //    > .zip-code {
  //      flex-basis: calc(33.33% - 8px);
  //    }
  //
  //    > .country {
  //      flex-basis: calc(66.66% - 8px);
  //    }
  //  }
}
