header.site-header {
  padding: 64px 0 16px;

  .account-links {
    a {
      color: $primary-color;
      font-size: 13px;
      font-weight: 800;
      text-transform: uppercase;
      padding: 2px 0px;
      margin: 0 16px;
      transition: $transition;
      border-bottom: 1px solid transparent;

      &:hover {
        border-bottom-color: $primary-color;
      }
    }
  }

  @include small {
    padding-top: 16px;

    .site-header-inner {
      flex-wrap: wrap;

      .account-links {
        width: 100%;
        text-align: left;
        margin-top: 16px;

        a {
          margin-left: 0;
          margin-right: 16px;
        }
      }
    }
  }
}
